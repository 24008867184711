import React from "react";
import "./Footer.scss";

function Footer() {
  return (
      <div className="copyright">
        <p>&#169; Lietuvos Respublikos Seimo kanceliarija, LRT</p>
      </div>
  );
}

export default Footer;
