import React from "react";
import Logo from "../../assets/images/Logo.png";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { GhostButton } from "../Buttons";
import "./Header.scss";

const Navbar = () => {

  function closeNav() {
    document.getElementById("menu").style.width = "0";
  }
  function openNav() {
    document.getElementById("menu").style.width = "100vw";
  }
  const location = useLocation();

  const getButtonColor = (path) => {
    return location.pathname === path ? "#F15A29" : "#373F41";
  };

  const getButtonColorMob = (path) => {
    return location.pathname === path ? "#F15A29" : "#FFFFFF";
  };

    return (
      <div className="navbar">
        <div className="leftSide">
          <Link to="/">
            <img src={Logo} alt="Demokratijos konkurso logotipas"/>
          </Link>
        </div>
        <div className="hiddenMenu" id="menu">
            <button className="closebtn" onClick={closeNav} aria-label="Uzdaryti meniu">&times;</button>
            <GhostButton variant="white" style={{ color: getButtonColorMob("/") }} to="/" onClick={() => { closeNav(); }}>APIE</GhostButton>
            <GhostButton variant="white" style={{ color: getButtonColorMob("/registracija") }} to="/registracija"  onClick={closeNav} aria-label="Registruotis"> REGISTRACIJA </GhostButton>
            <GhostButton variant="white" style={{ color: getButtonColorMob("/nuostatai") }} to="/nuostatai"  onClick={closeNav} aria-label="Skaityti konkurso nuostatas"> NUOSTATAI </GhostButton>
            <GhostButton variant="white" style={{ color: getButtonColorMob("/privatumas") }} to="/privatumas"  onClick={closeNav} aria-label="Skaityti konkurso privatumo politika"> PRIVATUMAS </GhostButton>
          </div>
        <div className="rightSide">
          <GhostButton to="/" style={{ color: getButtonColor("/") }} aria-label="Apie konkursą" >Apie</GhostButton>
          <GhostButton to="/registracija" style={{ color: getButtonColor("/registracija") }} aria-label="Registruotis">Registracija</GhostButton>
          <GhostButton to="/nuostatai" style={{ color: getButtonColor("/nuostatai") }} aria-label="Skaityti nuostatas">Nuostatai</GhostButton>
          <GhostButton to="/privatumas" style={{ color: getButtonColor("/privatumas") }} aria-label="Skaityti privatumo politika">Privatumas</GhostButton>
        </div>
        <button onClick={openNav} id="menuBut" aria-label="Atidaryti meniu">
          &equiv;
        </button>
      </div>
      
    );
  }
  
  export default Navbar;